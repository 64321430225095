import React, { useState, useEffect, useRef } from "react"
import { graphql, useStaticQuery } from "gatsby"
import moment from "moment"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { CAMPAIGN_POSTS, DEV_ONLY_PATHS } from "./../controlled/index"
import useLiveControl from "../controlled/useLiveControl"

const BlogArchive = () => {
  const { wpgraphql: data } = useStaticQuery(graphql`
    query BlogArchiveQuery {
      wpgraphql {
        posts(first: 1000, where: { orderby: { field: DATE, order: DESC } }) {
          edges {
            node {
              id
              slug
              title
              date
              featuredImage {
                node {
                  altText
                  sourceUrl
                }
              }
              blog {
                blogField {
                  private
                }
              }
            }
          }
        }
      }
    }
  `)

  const MONTHS = [
    { name: "Jan", value: "01" },
    { name: "Feb", value: "02" },
    { name: "Mar", value: "03" },
    { name: "Apr", value: "04" },
    { name: "May", value: "05" },
    { name: "Jun", value: "06" },
    { name: "Jul", value: "07" },
    { name: "Aug", value: "08" },
    { name: "Sep", value: "09" },
    { name: "Oct", value: "10" },
    { name: "Nov", value: "11" },
    { name: "Dec", value: "12" },
  ]

  const currentYear = moment().format("YYYY")
  const currentMonth = {
    name: moment().format("MMM"),
    value: moment().format("MM"),
  }

  const { isStaging } = useLiveControl()

  const yearsRef = useRef(null)
  const monthsRef = useRef(null)

  const publicPosts = data.posts.edges.filter(({ node }) => {
    const firstLevel =
      !node?.blogField?.private && !CAMPAIGN_POSTS.includes(node?.slug)
    const secondLevel = !DEV_ONLY_PATHS.includes("/blog/" + node?.slug)

    return isStaging ? firstLevel : firstLevel && secondLevel
  })

  const [activeYear, setActiveYear] = useState(currentYear)
  const [activeMonth, setActiveMonth] = useState(currentMonth)
  const [showYears, setShowYears] = useState(false)
  const [showMonths, setShowMonths] = useState(false)
  const [posts, setPosts] = useState([])

  const publicationYears = data.posts.edges
    .map(item => moment(item.node.date).format("YYYY"))
    .reduce((years, current) => {
      if (years.indexOf(current) === -1) years.push(current)
      return years
    }, [])
    .sort()

  const disableNext =
    activeYear === currentYear && activeMonth.value === currentMonth.value

  const disablePrevious =
    activeYear === publicationYears[0] && activeMonth.value === "01"

  const toggleYears = () => {
    setShowYears(current => !current)
    setShowMonths(false)
  }

  const toggleMonths = () => {
    setShowMonths(current => !current)
    setShowYears(false)
  }

  const handleClosePopups = () => {
    setShowYears(false)
    setShowMonths(false)
  }

  const handleActiveMonth = month => {
    setActiveMonth(month)
    setShowMonths(false)
  }

  const handleActiveYear = year => {
    setActiveYear(year)
    setShowYears(false)
    if (year === currentYear && +activeMonth.value > +currentMonth.value) {
      setActiveMonth(currentMonth)
    }
  }

  const truncate = (str, n) => {
    return str.length > n ? str.substr(0, n - 1) + "..." : str
  }

  const addActiveClass = condition => {
    return condition ? "util--active" : ""
  }

  const addRotateClass = condition => {
    return condition ? "archives__actions__icon--rotate" : ""
  }

  const addShowClass = condition => {
    return condition ? "util--show" : ""
  }

  const handleSwipe = direction => {
    handleClosePopups()
    const activeYearIndex = publicationYears.indexOf(activeYear)
    const currentMonthIndex = MONTHS.findIndex(
      ({ value }) => activeMonth.value === value
    )

    switch (direction) {
      case "next":
        setActiveYear(current =>
          activeMonth.name === "Dec" && current !== currentYear
            ? publicationYears[activeYearIndex + 1]
            : current
        )
        setActiveMonth(current =>
          disableNext
            ? current
            : current.name === "Dec"
            ? MONTHS[0]
            : MONTHS[currentMonthIndex + 1]
        )
        break

      case "previous":
        setActiveYear(current =>
          activeMonth.name === "Jan" && current !== publicationYears[0]
            ? publicationYears[activeYearIndex - 1]
            : current
        )
        setActiveMonth(current =>
          disablePrevious
            ? current
            : current.name === "Jan"
            ? MONTHS[11]
            : MONTHS[currentMonthIndex - 1]
        )
        break

      default:
        break
    }
  }

  useEffect(() => {
    const _posts = publicPosts.filter(
      ({ node }) =>
        node.date.substring(0, 7) === `${activeYear}-${activeMonth.value}`
    )
    setPosts(_posts)
  }, [activeYear, activeMonth, publicPosts])

  useEffect(() => {
    const twoMonthsAgoFullDate = moment().add(-2, "month")
    const twoMonthsAgoInMonths = {
      name: twoMonthsAgoFullDate.format("MMM"),
      value: twoMonthsAgoFullDate.format("MM"),
    }

    setActiveYear(twoMonthsAgoFullDate.format("YYYY"))
    setActiveMonth(twoMonthsAgoInMonths)
  }, [])

  return (
    <div
      className="archives card"
      onMouseLeave={handleClosePopups}
      role="complementary"
    >
      <h3 className="archives__heading">Blog Archives</h3>
      <div className="archives__actions">
        <button
          type="button"
          title="Previous"
          className="archives__actions__swipe"
          onClick={() => handleSwipe("previous")}
          disabled={disablePrevious}
        >
          <FontAwesomeIcon icon="chevron-left" color={"#0077c8"} />
        </button>
        <button
          type="button"
          className={`archives__actions__jump ${addActiveClass(showYears)}`}
          onClick={toggleYears}
        >
          <span>{activeYear}</span>
          <FontAwesomeIcon
            icon="caret-down"
            className={`archives__actions__icon ${addRotateClass(showYears)}`}
          />
        </button>
        <button
          type="button"
          className={`archives__actions__jump ${addActiveClass(showMonths)}`}
          onClick={toggleMonths}
        >
          <span>{activeMonth.name}</span>
          <FontAwesomeIcon
            icon="caret-down"
            className={`archives__actions__icon ${addRotateClass(showMonths)}`}
          />
        </button>
        <button
          type="button"
          title="Next"
          className="archives__actions__swipe"
          onClick={() => handleSwipe("next")}
          disabled={disableNext}
        >
          <FontAwesomeIcon icon="chevron-right" color={"#0077c8"} />
        </button>
      </div>
      <div className="archives__dates">
        <ul
          ref={yearsRef}
          className={`archives__dates__years ${addShowClass(showYears)}`}
        >
          {publicationYears.map(year => (
            <li key={year} className="archives__dates__years__item">
              <button
                className={`archives__dates__years__item__btn ${addActiveClass(
                  activeYear === year
                )}`}
                type="button"
                onClick={() => handleActiveYear(year)}
              >
                {year}
              </button>
            </li>
          ))}
        </ul>

        <ul
          ref={monthsRef}
          className={`archives__dates__months ${addShowClass(showMonths)}`}
        >
          {MONTHS.map(month => (
            <li key={month.name} className="archives__dates__months__item">
              <button
                className={`archives__dates__months__item__btn ${addActiveClass(
                  activeMonth.value === month.value
                )}`}
                type="button"
                onClick={() => handleActiveMonth(month)}
                disabled={
                  activeYear === currentYear &&
                  month.value > moment().format("MM")
                }
              >
                {month.name}
              </button>
            </li>
          ))}
        </ul>
      </div>

      <div className="archives__posts">
        {posts.length ? (
          <ul className="archives__posts__list">
            {posts.map(({ node: { id, date, featuredImage, slug, title } }) => (
              <li key={id} className="archives__posts__list__item">
                <div className="archives__posts__list_item__image">
                  <a href={"/blog/" + slug + "/"}>
                    <img
                      src={featuredImage?.node?.sourceUrl}
                      alt={featuredImage?.node?.altText}
                      className="img-fluid border"
                    />
                  </a>
                </div>
                <div className="archives__posts__list_item__summary">
                  <h3>
                    <a
                      href={"/blog/" + slug + "/"}
                      dangerouslySetInnerHTML={{ __html: truncate(title, 55) }}
                    ></a>
                  </h3>
                  <small>{moment(date).format("MMMM D, Y")}</small>
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <p className="archives__posts_empty">
            No posts for {activeMonth.name}, {activeYear}
          </p>
        )}
      </div>
    </div>
  )
}

export default BlogArchive
